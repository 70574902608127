import dynamic from "next/dynamic";
import { KeyResourcesItemProps } from "components/atoms/KeyResourcesItem";
import { useMetadata } from "hooks/useMetadata";
import { LatestCaseStudy, getPageTranslations } from "utils/server";
import { GetStaticProps } from "next";
import { YuLifeLocale } from "@hooks";
import { StaticRoutes } from "@routes";
import { isLocaleInStaticRoute } from "@utils";
import { Meta } from "components/atoms/Meta";
import { strapiService } from "@services";

const Index = dynamic(() => import("../components/pages/Home"));

export default function IndexComponent({
  locale,
  keyResources,
  latestCaseStudy,
}: {
  locale: YuLifeLocale;
  keyResources: KeyResourcesItemProps[];
  latestCaseStudy: LatestCaseStudy;
}): JSX.Element {
  const metadata = useMetadata("index");

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <>
      <Meta locale={locale} {...metadata} />
      <Index keyResources={keyResources || []} latestCaseStudy={latestCaseStudy} />
    </>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const locale = context.locale as YuLifeLocale;

  if (!isLocaleInStaticRoute(locale, StaticRoutes.index)) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      ...(await strapiService.getGenericProps(locale)),
      messages: await getPageTranslations(locale, "home"),
    },
  };
};
